<template>
  <div class="grid">
    <div class="md:col-6">
      <div class="grid" style="margin-top: 0px">
        <label class="md:col-3" style="font-weight: bold;">{{$t('shipment.code')}}</label>
        <div class="md:col-3" style="font-weight: bold">{{ shipment.shipmentCode }}</div>
      </div>
      <div class="grid" style="margin-top: 0px">
        <label class="md:col-3" style="font-weight: bold;">{{$t('shipment.agent')}}</label>
        <div class="md:col-3" style="font-weight: bold">{{ shipment.agentCode }}</div>
      </div>
      <div class="grid" style="margin-top: 0px">
        <label class="md:col-3" style="font-weight: bold;">{{$t('shipment.referenceCode')}}</label>
        <div class="md:col-3" style="font-weight: bold">{{ shipment.referenceCode }}</div>
      </div>
    </div>
    <div class="md:col-6">
      <div class="grid" style="margin-top: 0px">
        <label class="md:col-3" style="font-weight: bold;">{{$t('shipment.weightPartnerLbs')}}</label>
        <div class="md:col-3" style="font-weight: bold">{{ shipment.weightPartnerLbs || 0 }} {{ $t('unit.lbs') }} / {{shipment.weightPartnerKg || 0}} {{$t('unit.kg')}}</div>
      </div>
      <div class="grid" style="margin-top: 0px">
        <label class="md:col-3" style="font-weight: bold;">{{$t('shipment.weightActual')}}</label>
        <div class="md:col-3" style="font-weight: bold">{{ shipment.weightActualLbs || 0 }} {{$t('unit.lbs')}} / {{shipment.weightActualKg || 0}} {{$t('unit.kg')}}</div>
      </div>
    </div>
    <CrudTable ref="shipment" :entities="entities"
      :meta="metaTable"
      @tableAction="onTableAction"
      @itemAction="onItemAction"
      @cell-edit-complete="onCellEditComplete"/>
  <!--
    <div class="col-12 md:col-12">
      <DataTable ref="dt" :value="entities" v-model:selection="selectedEntity" dataKey="id" editMode="cell"
          @cell-edit-complete="onCellEditComplete"
          :showGridlines=true
          :paginator="true" :rows="10" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} packages" responsiveLayout="scroll">
        <template #header>
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">{{$t('package.title_list')}}</h5>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Search..." />
            </span>
          </div>
        </template>

        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column field="code" :header="$t('package.arrival_date')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title">{{$t('package.arrival_date')}}</span>
            {{this.formatDate(slotProps.data.arrivalDate)}}
          </template>
        </Column>
        <Column field="package_code" :header="$t('package.package_code')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title">{{$t('package.package_code')}}</span>
            {{slotProps.data.packageCode}}
          </template>
        </Column>
        <Column field="shipment_code" :header="$t('package.shipment_code')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title">{{$t('package.shipment_codes')}}</span>
            {{slotProps.data.shipmentCodes}}
          </template>
        </Column>
        <Column field="weight" :header="$t('package.weight')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title">{{$t('package.weight')}}</span>
            {{slotProps.data.weightLbs}} {{$t('unit.lbs')}} / {{tokg(slotProps.data.weightLbs)}} {{$t('unit.kg')}}
          </template>
        </Column>
        <Column field="actualWeight" :header="$t('package.actual_weight')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #editor="{ data, field }">
              <InputNumber v-model="data[field]" mode="decimal" :suffix=" $t('unit.kg')">

              </InputNumber>
          </template>
          <template #body="slotProps">
            <span class="p-column-title">{{$t('package.actual_weight')}}</span>
            {{tolbs(slotProps.data.actualWeightKg)}} {{$t('unit.lbs')}} / {{slotProps.data.actualWeightKg}} {{$t('unit.kg')}}
          </template>
        </Column>
        <Column field="storeStatus" :header="$t('package.store_status')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title">{{$t('package.store_status')}}</span>
            {{storeStatus(slotProps.data.storeStatus)}}
          </template>
        </Column>
        <Column headerStyle="min-width:10rem;">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="openReceiptEdit(slotProps.data)" />
            <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="confirmDeleteEntity(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </div>
          -->
  </div>
  <Dialog v-model:visible="showPackageImages" class="images">
    <template #header>
      <h3>{{$t('package.images')}}</h3>
    </template>
    <Galleria v-model:visible="showPackageImages" :value="images" :responsiveOptions="responsiveOptions" :numVisible="5" :circular="true" containerStyle="max-width: 640px"
      :showItemNavigators="true" :showThumbnails="false">
      <template #item="slotProps">
          <img :src="slotProps.item.imageSrc" :alt="slotProps.item.alt" style="width: 100%; display: block;" />
      </template>

      <template #thumbnail="slotProps">
          <img :src="slotProps.item.thumbnailSrc" :alt="slotProps.item.alt" style="display: block;" />
      </template>
    </Galleria>
    <!--
    <template #footer>
      <Button :label="$t('button.save')" icon="pi pi-save" @click="onSaveMessage" autofocus />
    </template>
    -->
  </Dialog>
</template>
<script>
import CrudTable from '@/pages/common/CrudTable';
import PackageService from '@/service/PackageService';
import ShipmentService from '@/service/ShipmentService';
import ReceiptService from '@/service/ReceiptService';
import AuthService from '@/service/AuthService';
import ImageService from '@/service/ImageService';
import adjustment from '@/utils/adjustment';
import formatter from '@/utils/formatter';
import {converter} from '@/utils/common';
import {Consts} from '@/utils/consts';
import preview from '@/mixins/preview';
import { writeFile, utils } from "xlsx";
import confirm from '@/mixins/shipment_confirm';
export default {
  components: {CrudTable},
  mixins: [preview, confirm],
  data() {
    //const $t = this.$t;
    const metaTable = {
      entityName: "package",
      expander: false,
      searchable: true,
      rowEditor: true,
      editMode: "cell",
      sortMode: "multiple",
      multiSortMeta: [{field:"createdDate", order: -1}],
      actionsHeaderStyle: "width:5%; min-width:5rem;",
      header: {
        title: ""
      },
      paginator:{
        enable: true,
        rows: Consts.DEFAULT_PAGE_ROWS,
        rowsPerPageOptions: Consts.ROW_PER_PAGE_OPTIONS,
        currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} packages",
      },
      filter: {
        filterDisplay: "menu",
        globalFilterFields: ['shipmentCode','awbCode']
      },
      columns: [
        {
          name: "createdDate",
          headerStyle: "width:8%; min-width:8rem;",
          filter: true,
          dataType: "date",
          formatter: formatter.formatDate,
        },
        {
          name: "packageCode",
          headerStyle: "width:10%; min-width:12rem;",
          filter: true,
          editor: {
            enable: false,
          },
          //component: PackageCodeTemplate
          //component: this.createPackageCode,
        },
        {
          name: "shipmentCode",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true
        },
        {
          name: "receiptCode",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
          link: this.openReceipt,
        },
        {
          name: "shipmentReferenceCode",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
        },
        {
          name: "receiptReferenceCode",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
        },
        {
          name: "awbCode",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
        },
        {
          name: "weightPartnerLbs",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
          formatter: converter.formatLbsWeight,
        },
        {
          name: "weightActualKg",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
          formatter: converter.formatKgWeight,
        },
        {
          name: "processStatus",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
          // filterOptions: [1, 2, 3, 4, 5].map(status => ({value: status, label: format_process_status(status)})),
          // dataType: "numeric",
          // formatter: format_process_status,
        },
        {
          name: "storeStatus",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
          formatter: this.formatStoreStatus,
        },
        {
          name: "storeNote",
          headerStyle: "width:20%; min-width:10rem;",
          filter: true,
          editor: {
            enable: false,
          },
        },
        {
          name: "description",
          headerStyle: "width:30%; min-width:30rem;",
          filter: true,
        },
      ],
      tableActions: [
        {
          name: "filterclear",
          icon: "pi pi-filter-slash",
          style: "p-button-outlined mr-2"
        },
        {
            name: "send",
            icon: "pi pi-send",
            style: "p-button mr-2",
            condition:"status === 'inited'"
        },
        {
          name: "edit",
          icon: "pi pi-pencil",
          style: "p-button mr-2",
          condition:"shipment.id"
        },
        {
          name: "addPackage",
          icon: "pi pi-box",
          style: "p-button mr-2",
          condition:"shipment.id"
        },
        {
          name: "export",
          icon: "pi pi-file-export",
          style: "p-button mr-2",
          condition:"shipment.id"
        },
      ],
      itemActions: [
        // {
        //   name: "edit",
        //   icon: "pi pi-pencil",
        //   style: "p-button-rounded mr-2",
        //   condition: "mode != 'edit'"
        // },        
        // {
        //   name: "delete",
        //   icon: "pi pi-trash",
        //   style: "p-button-rounded p-button-warning mr-2",
        //   condition: "mode != 'edit'"
        // },
        {
          name: "print",
          icon: "pi pi-print",
          style: "p-button-rounded mr-2"
        }
      ],
      metaSubTable: {
        entityName: "packageItem",
        dataField: "packageItems",
        paginator:{
          enable: false,
        },
        columns: [
        {
          name: "name",
          headerStyle: "width:30%; min-width:10rem;",
          filter: true,
          editor: {
            enable: false
          }
        },
        {
          name: "quantity",
          headerStyle: "width:20%; min-width:10rem;",
          filter: true,
          editor: {
            enable: false
          }
        },
        {
          name: "description",
          headerStyle: "width:50%; min-width:10rem;",
          filter: true,
          editor: {
            enable: false
          }
        }

        ]
      }
    };
    return {
      entities: [],
      shipment: {},
      selectedEntity: null,
      currentUser: null,
      metaTable,
      showPackageImages: false,
      images: []
    }
  },
  created() {
    this.service = PackageService;
	},
  async mounted() {
    this.$watch(
      () => this.$route.params,
      (params) => {
        this._init(params.id);
        //console.log('route path has changed from ' +from+' to '+to )
      }
    );
    this._init(this.$route.params.id);
    this.currentUser = AuthService.currentUser;
  },
  methods: {
    async _init(id) {
      if (id) {
        const shipment = await ShipmentService.get(id, {
          includes: "packages"
        });
        this.shipment = adjustment.adjustShipment(shipment);
        let info = this.shipment.shipmentCode;
        if (this.shipment.awbCode) {
            info += `- ${this.shipment.awbCode}`;
          }
          if (this.shipment.departure) {
            let departure = this.formatDate(this.shipment.departure);
            info += `- ${departure}`;
          }
          this.metaTable.header.title = info;
          if (Array.isArray(this.shipment.packages)) {
            this.entities = this.shipment.packages;//.map(id=>mapPackages[id]);
          } else {
            this.entities = [];
          }
          this.entities.forEach(pkg => {
            pkg.description = pkg.itemDtos?.map(item => item.quantity + " " + item.name).join(', ');
        })
      }
    },
    formatDate(jsDate) {
      return formatter.formatDate(jsDate);
    },
    tokg(lbs) {
      return converter.tokg(lbs);
    },
    tolbs(kg) {
      return converter.tolbs(kg);
    },
    storeStatus(status) {
      return isNaN(status) ? "" : this.$t("package.store_status_" + status);
    },
    onTableAction(sAction) {
      if(sAction == 'export') {
        this.exportManifest()
      } else if (sAction == 'send') {
        this.onSendShipment(this.shipment);
      } else if (sAction == 'edit') {
        this.$router.push({ name: 'agent_shipment_edit', params: {id: this.shipment.id} });
      } else if (sAction == 'addPackage') {
        this.$router.push({ name: 'agent_package_create', params: {shipmentId: this.shipment.id} });
      }
    },
    onItemAction(sAction, entity, index) {
      if(sAction == 'edit') {
        this.$router.push({ name: 'agent_package_edit', params: {receiptId: entity.id} });
      } else if (sAction == 'print') {
        ReceiptService.get(entity.id).then(data => {
          if (Array.isArray(data) && data.length == 1) {
            const receiptId = data[0].id;           
            //const routeData = this.$router.resolve({ name: 'print_preview', params: {resource: "shipment", id: receiptId, packageId: entity.id} });
            const routeData = this.$router.resolve({ name: 'print_preview', params: {resource: "receipt", id: receiptId, packageId: entity.id} });
            window.open(routeData.href); 
          }
        });
      } else if (sAction == 'qrcode') {
        console.log(entity);
      } else if (sAction == 'images') {
        this.showPackageImages = true;
        this.loadImages(entity);
      }
      console.log(index)
    },
    onCellEditComplete(event) {
      let { data, newValue, field } = event;
      switch (field) {
          case 'actualWeight':
              if (this.isPositiveInteger(newValue)) {
                  data[field] = newValue;
                  this.service.updateField(data.id, field, newValue);
              } else {
                  event.preventDefault();
              }
          break;

          default:
              if (newValue && newValue.trim().length > 0) {
                  data[field] = newValue;
                  this.service.updateField(data.id, field, newValue);
              } else {
                  event.preventDefault();
              }
          break;
      }
    },
    exportManifest() {
      let receipts = {};
      let selectedPackages = this.entities;
      selectedPackages = selectedPackages.sort(
        (a, b) => a.packageCode < b.packageCode
      );
      if (Array.isArray(this.shipment.receipts)) {
        this.shipment.receipts.forEach((receipt) => {
          receipts[receipt.receiptCode] = receipt;
        });
      }
      /*
      selectedPackages.forEach(pkg => {
        const receipt = this._addPackage(receipts[pkg.receiptCode], pkg)
        if (receipt) {
          receipts[pkg.receiptCode] = receipt
        }
      });
      */
      //Manifests
      const manifests = this._createManifests(selectedPackages, receipts);
      // Shipping form
      const shipingForm = this._createShippingForm(selectedPackages, receipts);

      var wb = utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      utils.book_append_sheet(wb, manifests, "Manifests"); // sheetAName is name of Worksheet
      utils.book_append_sheet(wb, shipingForm, "ShippingList"); // sheetAName is name of Worksheet
      // export Excel file
      const fileName = "shipment-" + this.shipment.shipmentCode + ".xlsx";
      writeFile(wb, fileName); // name of the file is 'book.xlsx'
    },
    _createManifests(selectedPackages, mapReceipts) {
      const manifests = selectedPackages.map((pkg, ind) => {
        const receipt = mapReceipts[pkg.receiptCode];
        const hdrPackageCode = this.$t("package.packageCode");
        const hdrWeightLbs = this.$t("shipment.weightLbs");
        const hdrWeightKg = this.$t("shipment.weightKg");
        const hdrSenderName = this.$t("shipment.senderName");
        const hdrSenderAddress = this.$t("shipment.senderAddress");
        const hdrSenderPhone = this.$t("shipment.senderPhone");
        const hdrRecipientName = this.$t("shipment.recipientName");
        const hdrRecipientAddress = this.$t("shipment.recipientAddress");
        const hdrRecipientPhone = this.$t("shipment.recipientPhone");
        const hdrDescription = this.$t("shipment.description");
        return {
          No: ind + 1,
          [hdrPackageCode]: pkg.packageCode,
          ["Pcs"]: 1,
          [hdrWeightLbs]: receipt.weightLbs,
          [hdrWeightKg]: converter.tokg(pkg.weightLbs),
          [hdrSenderName]: receipt.dSenderName || receipt.senderName,
          [hdrSenderAddress]: receipt.dSenderAddress || receipt.senderAddress,
          [hdrSenderPhone]: receipt.dSenderPhone || receipt.senderPhone,
          [hdrRecipientName]: receipt.dRecipientName || receipt.recipientName,
          [hdrRecipientAddress]:
            receipt.dRecipientAddress || receipt.recipientAddress,
          [hdrRecipientPhone]:
            receipt.dRecipientPhone || receipt.recipientPhone,          
          ["Value"]: "GIFT",
          [hdrDescription]: pkg.itemDtos?.map((item) => item.quantity + " " + item.name)
            .join(", "),
        };
      });
      var sheet = utils.json_to_sheet(manifests, {
        origin: "A4",
      });
      sheet["!cols"] = [
        { wch: 6 },
        { wch: 12 },
        { wch: 10 },
        { wch: 10 },
        { wch: 18 },
        { wch: 30 },
        { wch: 10 },
        { wch: 18 },
        { wch: 30 },
        { wch: 10 },
        { wch: 6 },
        { wch: 30 },
      ];

      const totalWeight = selectedPackages.reduce(
        (total, pkg) => total + pkg.weightLbs,
        0
      );
      utils.sheet_add_aoa(sheet, [[this.shipment.shipmentCode]], {
        origin: "A1",
      });
      utils.sheet_add_aoa(sheet, [[this.shipment.awbCode]], {
        origin: "B1",
      });
      utils.sheet_add_aoa(
        sheet,
        [
          [
            this.$t("shipment.number_of_packages", {
              numPackages: selectedPackages.length,
            }),
          ],
        ],
        { origin: "A2" }
      );
      utils.sheet_add_aoa(
        sheet,
        [[this.$t("shipment.totalWeight", { weight: totalWeight })]],
        { origin: "D2" }
      );
      return sheet;
    },
    _createShippingForm(selectedPackages, mapReceipts) {
      const hdrIndex = this.$t("package.noOfPCs");
      const hdrReceiptNumber = this.$t("shipment.receiptNumber");
      const hdrPackingNumber = this.$t("shipment.packingNumber");
      const hdrShipmentNumber = this.$t("shipment.shipmentNumber");
      const hdrWeightLbs = this.$t("shipment.weightLbs");
      const hdrWeightKg = this.$t("shipment.weightKg");
      const hdrRecipientName = this.$t("shipment.recipientName");
      const hdrRecipientAddress = this.$t("shipment.recipientAddress");
      //const hdrRecipientDistrict = this.$t('shipment.recipientDistrict');
      //const hdrRecipientCity = this.$t('shipment.recipientCity');
      const hdrRecipientPhone = this.$t("shipment.recipientPhone");
      const hdrDescription = this.$t("shipment.description");
      const shippingForm = selectedPackages.map((pkg, ind) => {
        const receipt = mapReceipts[pkg.receiptCode];
        return {
          [hdrIndex]: ind + 1,
          [hdrReceiptNumber]: pkg.receiptCode,
          [hdrPackingNumber]: pkg.packageCode,
          [hdrShipmentNumber]: this.shipment.shipmentCode,
          [hdrWeightLbs]: pkg.weightLbs,
          [hdrWeightKg]: converter.tokg(pkg.weightLbs),
          [hdrRecipientName]: receipt.dRecipientName || receipt.recipientName,
          [hdrRecipientAddress]:
            receipt.dRecipientAddress ||
            [
              receipt.recipientAddress,
              receipt.receiptDistrict,
              receipt.receiptProvince,
            ].join(","),
          [hdrRecipientPhone]:
            receipt.dRecipientPhone || receipt.recipientPhone,
          [hdrDescription]: pkg.itemDtos?.map((item) => item.quantity + " " + item.name)
            .join(", "),
        };
      });
      var sheet = utils.json_to_sheet(shippingForm, {
        origin: "A4",
      });
      sheet["!cols"] = [
        { wch: 6 },
        { wch: 10 },
        { wch: 12 },
        { wch: 8 },
        { wch: 6 },
        { wch: 6 },
        { wch: 18 },
        { wch: 30 },
        { wch: 10 },
        { wch: 30 },
      ];
      const totalWeight = selectedPackages.reduce(
        (total, pkg) => total + pkg.weightLbs,
        0
      );
      utils.sheet_add_aoa(sheet, [[this.shipment.shipmentCode]], {
        origin: "A1",
      });
      utils.sheet_add_aoa(sheet, [[this.shipment.awbCode]], {
        origin: "B1",
      });
      utils.sheet_add_aoa(
        sheet,
        [
          [
            this.$t("shipment.number_of_packages", {
              numPackages: selectedPackages.length,
            }),
          ],
        ],
        { origin: "A2" }
      );
      utils.sheet_add_aoa(
        sheet,
        [[this.$t("shipment.totalWeight", { weight: totalWeight })]],
        { origin: "D2" }
      );
      return sheet;
    },
    loadImages(receiptPkg) {
      if(receiptPkg) {
        ImageService.loadImages(receiptPkg)
          .then((response) => {
            //const baseUrl = Utils.getImageBase();
            if (Array.isArray(response)) {
              this.images = response
            }
          })
      }
    },
    isPositiveInteger(val) {
        let str = String(val);
        str = str.trim();
        if (!str) {
            return false;
        }
        str = str.replace(/^0+/, "") || "0";
        var n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    },
    openShipment(data) {
      this.$router.push({
        name: "agent_shipment_detail",
        params: { id: data.shipmentId },
      });
    },
    openReceipt(data) {
      this.$router.push({
        name: "users_receipt_view",
        params: { id: data.receiptId },
      });
    },
  }
}
</script>
<style>
  .images {
    min-width:600px;
  }
</style>
